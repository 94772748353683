import React from 'react';
import { Button, Intent, Classes } from '@blueprintjs/core';
import * as Backoffice from '@client/backoffice/Dto';
import { LabelWithTextInput, LykkeForm } from '@elements/lykke-html/form/LykkeForm';
import { ILocalizedProps } from '@elements/localization';
import { inject } from 'mobx-react';
import { FrontendError } from '@client/backoffice/CustomErrors';
import { BackofficeErrorCodes } from '@client/backoffice/ErrorCodes';
import { omit, every } from 'lodash';
import styled from 'styled-components';

const FormInputWithNote = styled.div`
  display: flex;

  > *:first-child {
    width: 170px;
  }

  > *:last-child {
    padding-top: 24px;
    margin-left: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

interface Props extends ILocalizedProps {
  regulation: Backoffice.Regulations.RegulationContract;
  sourceRegulatoryTypeId?: string;
  onCancel: () => void;
  onSubmit: (regulatoryTypeData: Backoffice.RegulatoryType.RegulatoryTypeContract, sourceRegulatoryTypeId?: string) => void;
  loading: boolean;
  serverErrors: FrontendError[];
}

interface State {
  formData: Backoffice.RegulatoryType.RegulatoryTypeContract;
  fieldErrors: {[P in keyof Backoffice.RegulatoryType.RegulatoryTypeContract]?: string};
}

const getEmptyFormData = () => ({
  id: ''
} as Backoffice.RegulatoryType.RegulatoryTypeContract);

@inject('i18n')
export class AddRegulatoryTypeForm extends React.Component<Props, State> {
  public state: State = {
    formData: getEmptyFormData(),
    fieldErrors: {},
  };

  private handleRegulatoryTypeIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFormData = {
      ...this.state.formData,
      id: e.target.value
    };

    this.setState({ formData: newFormData });
  }

  private handleSubmit = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    if (this.validate()) {
      this.props.onSubmit({
        ...this.state.formData,
        id: this.fullId
      }, this.props.sourceRegulatoryTypeId);
    }
  }

  private validateId = () => {
    const t = this.props.i18n.RegulationRules.RegulatoryTypes.ClientErrors;
    const value = this.state.formData.id;

    if (!value) {
      this.setState(state => ({
        fieldErrors: {...state.fieldErrors, id: t.RegulatoryTypeIdFieldRequired}
      }));
      return false;
    } else {
      this.setState(state => ({
        fieldErrors: omit(state.fieldErrors, 'id')
      }));
      return true;
    }
  }

  private validate = () => {
    return every([
      this.validateId(),
    ]);
  }

  private getServerErrorMessages(field: keyof Backoffice.RegulatoryType.RegulatoryTypeContract) {
    return this.props.serverErrors
      ?.filter(e => e.errorCode as BackofficeErrorCodes === 'FieldValidationFailed' && e.fieldNames.includes(field))
      .map(e => e.errorMessage) ?? [];
  }

  private getClientErrorMessage(field: keyof Backoffice.RegulatoryType.RegulatoryTypeContract) {
    return this.state.fieldErrors[field];
  }

  private formatErrorMessages(messages: string[]) {
    return messages.filter(m => !!m).join('; ');
  }

  private get fullId() {
    return this.state.formData.id ? `${this.props.regulation.id}.${this.state.formData.id}` : '';
  }

  public render() {
    const { loading } = this.props;
    const t = this.props.i18n.RegulationRules.RegulatoryTypes;

    return (
      <LykkeForm>
        <div className={Classes.DIALOG_BODY}>
          <FormInputWithNote>
            <LabelWithTextInput
              autoFocus={true}
              label={t.RegulatoryTypeIdLabel}
              value={this.state.formData.id}
              onChange={this.handleRegulatoryTypeIdChange}
              onBlur={this.validateId}
              error={this.formatErrorMessages([this.getClientErrorMessage('id'), ...this.getServerErrorMessages('id')])}
            />
            <div>{this.fullId}</div>
          </FormInputWithNote>
          {this.props.sourceRegulatoryTypeId && <LabelWithTextInput
            label={t.SourceRegulatoryTypeIdLabel}
            disabled={true}
            value={this.props.sourceRegulatoryTypeId} />}
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              onClick={this.props.onCancel}
              disabled={loading}
              intent={Intent.NONE}
            >
              {t.CancelButtonLabel}
            </Button>
            <Button
              type='submit'
              onClick={this.handleSubmit}
              disabled={loading}
              loading={loading}
              intent={Intent.SUCCESS}
            >
              {this.props.sourceRegulatoryTypeId ? t.DuplicateOkButtonLabel : t.CreateButtonLabel}
            </Button>
          </div>
        </div>
      </LykkeForm>
    );
  }
}