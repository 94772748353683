import React from 'react';
import { Button, Intent, Classes } from '@blueprintjs/core';
import * as Backoffice from '@client/backoffice/Dto';
import { LykkeForm, LabelWithTextInput, LabelWithNumberInput, LabelWithDateInput, LabelWithSelect } from '@elements/lykke-html/form/LykkeForm';
import { FrontendError } from '@client/backoffice/CustomErrors';
import { every, isEqual, omit } from 'lodash';
import { BackofficeErrorCodes } from '@client/backoffice/ErrorCodes';
import { formatAsISO8601Date, getMaxCalendarDate, getMinCalendarDate, isWeekend, toMoment } from '@scripts/utils/date-util';
import moment from 'moment';
import { I18n } from '@elements/localization/I18n';
import { TripleStateSelect } from '@elements/triple-state-select/TripleStateSelect';
import { Row, Col, Space } from 'antd';
import styled from 'styled-components';
import { NO_EDIt_LABEL_VALUE } from '../UnderlyingsView';

const StyledBlockDiv = styled.div`
  > h5 {
    font-size: 12px;
  }

  > div {
    border: 1px solid #d1d1d1;
    padding: 10px;
    border-radius: 3px;
  }
`;

interface Props {
  sb: jc.Sandbox;
  itemToEdit?: Backoffice.Underlyings.UnderlyingContract;
  itemsToEdit?: Backoffice.Underlyings.UnderlyingContract[];
  itemToClone?: Backoffice.Underlyings.UnderlyingContract;
  refitParameters: Backoffice.RefitParameters.GetRefitParametersResponse;
  onCancel: () => void;
  onSubmit: (underlyingData: Backoffice.Underlyings.UnderlyingContract) => void;
  onEditFrozenStatusByMdsCode: (mdsCode: string, isFrozen: boolean) => Promise<boolean>;
  onRenameUnderlyingMdsCodeClick: (mdsCode: string) => void;
  loading: boolean;
  serverErrors: FrontendError[];
}

interface State {
  formData: Backoffice.Underlyings.UnderlyingContract;
  fieldErrors: {[P in keyof Backoffice.Underlyings.UnderlyingContract]?: string};
}

const getEmptyFormData = (editingMultiple: boolean, eligible871M: boolean | null) => ({
  name: '',
  almParam: null,
  cfiCode: '',
  eligible871M,
  expiryDate: '',
  isin: '',
  lastTradingDate: '',
  marketArea: editingMultiple ? NO_EDIt_LABEL_VALUE : '',
  maturityDate: '',
  mdsCode: '',
  repoSurchargePercent: null,
  spread: null,
  tradingCurrency: '',
  commodityBase: '',
  commodityDetails: '',
  baseCurrency: '',
  indexName: '',
  emirType: '',
  startDate: '',
  refitAssetClass: editingMultiple ? NO_EDIt_LABEL_VALUE : '',
  refitBasePdct: editingMultiple ? NO_EDIt_LABEL_VALUE : '',
  refitSubPdct: editingMultiple ? NO_EDIt_LABEL_VALUE : '',
  refitAddtlSubPdct: editingMultiple ? NO_EDIt_LABEL_VALUE : ''
} as Backoffice.Underlyings.UnderlyingContract);

export class AddEditUnderlyingForm extends React.Component<Props, State> {
  private readonly initialFormData: Backoffice.Underlyings.UnderlyingContract;
  private readonly i18n: I18n;

  public state: State = {
    formData: {
      ...(this.props.itemToEdit ??
      this.props.itemToClone ??
      getEmptyFormData(!!this.props.itemsToEdit, this.getEligible871M()))
    },
    fieldErrors: {},
  };

  private getEligible871M() {
    let eligible871M = null;
    if (this.props.itemsToEdit?.every(item => item.eligible871M === true)) {
      eligible871M = true;
    }
    if (this.props.itemsToEdit?.every(item => item.eligible871M === false)) {
      eligible871M = false;
    }
    return eligible871M;
  }

  constructor(props: Props) {
    super(props);

    this.i18n = this.props.sb.getService('localization').i18n;
    this.initialFormData = this.state.formData;
  }

  public get isDirty() {
    return !isEqual(this.state.formData, this.initialFormData);
  }

  private get marketAreaOptions() {
    const t = this.i18n.UnderlyingsView;
    const result = this.props.sb.constants.appSettings.market_areas.map(x => ({
      value: x,
      text: x
    }));
    if (!!this.props.itemsToEdit) {
      result.unshift({ value: NO_EDIt_LABEL_VALUE, text: t.NoEditLabel });
    } else if (!this.state.formData.marketArea.length) {
      result.unshift({ value: null, text: '' });
    }
    return result;
  }

  private get refitAssetClassOptions() {
    const t = this.i18n.UnderlyingsView;
    const result = this.props.refitParameters.assetClassList.map(x => ({
      value: x,
      text: x
    }));
    if (!!this.props.itemsToEdit) {
      result.unshift({ value: NO_EDIt_LABEL_VALUE, text: t.NoEditLabel });
    } else if (!this.state.formData.refitAssetClass?.length) {
      result.unshift({ value: null, text: '' });
    }
    return result;
  }

  private get refitBasePdctOptions() {
    const t = this.i18n.UnderlyingsView;
    const result = this.props.refitParameters.basePdctList.map(x => ({
      value: x,
      text: x
    }));
    result.unshift({ value: null, text: '' });
    if (!!this.props.itemsToEdit) {
      result.unshift({ value: NO_EDIt_LABEL_VALUE, text: t.NoEditLabel });
    }
    return result;
  }

  private get refitSubPdctOptions() {
    const t = this.i18n.UnderlyingsView;
    const result = this.props.refitParameters.subPdctList.map(x => ({
      value: x,
      text: x
    }));
    result.unshift({ value: null, text: '' });
    if (!!this.props.itemsToEdit) {
      result.unshift({ value: NO_EDIt_LABEL_VALUE, text: t.NoEditLabel });
    }
    return result;
  }

  private get refitAddtlSubPdctOptions() {
    const t = this.i18n.UnderlyingsView;
    const result = this.props.refitParameters.addtlSubPdctList.map(x => ({
      value: x,
      text: x
    }));
    result.unshift({ value: null, text: '' });
    if (!!this.props.itemsToEdit) {
      result.unshift({ value: NO_EDIt_LABEL_VALUE, text: t.NoEditLabel });
    }
    return result;
  }

  public resetToInitialWithNewMdsCode(mdsCode: string) {
    this.initialFormData.mdsCode = mdsCode;
    this.setState({ formData: this.initialFormData });
  }

  private handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFormData = {
      ...this.state.formData,
      name: e.target.value
    };

    this.setState({ formData: newFormData }, this.validateName);
  }

  private handleMdsCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFormData = {
      ...this.state.formData,
      mdsCode: e.target.value
    };

    this.setState({ formData: newFormData }, this.validateMdsCode);
  }

  private handleEligible871MChange = (value: boolean | null) => {
    const newFormData = {
      ...this.state.formData,
      eligible871M: value
    };

    this.setState({ formData: newFormData }, this.validateEligible871M);
  }

  private handleIsinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFormData = {
      ...this.state.formData,
      isin: e.target.value
    };

    this.setState({ formData: newFormData }, this.validateIsin);
  }

  private handleCfiCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFormData = {
      ...this.state.formData,
      cfiCode: e.target.value
    };

    this.setState({ formData: newFormData }, this.validateCfiCode);
  }

  private handleAlmParamChange = (value: number) => {
    const newFormData = {
      ...this.state.formData,
      almParam: value
    };

    this.setState({ formData: newFormData }, this.validateAlmParam);
  }

  private handleStartDateChange = (selectedDate: moment.Moment) => {
    const newFormData = {
      ...this.state.formData,
      startDate: selectedDate ? formatAsISO8601Date(selectedDate) : null
    };

    this.setState({ formData: newFormData }, this.validateStartDate);
  }

  private handleExpiryDateChange = (selectedDate: moment.Moment) => {
    const newFormData = {
      ...this.state.formData,
      expiryDate: selectedDate ? formatAsISO8601Date(selectedDate) : null
    };

    this.setState({ formData: newFormData });
  }

  private handleLastTradingDateChange = (selectedDate: moment.Moment) => {
    const newFormData = {
      ...this.state.formData,
      lastTradingDate: selectedDate ? formatAsISO8601Date(selectedDate) : null
    };

    this.setState({ formData: newFormData });
  }

  private handleMaturityDateChange = (selectedDate: moment.Moment) => {
    const newFormData = {
      ...this.state.formData,
      maturityDate: selectedDate ? formatAsISO8601Date(selectedDate) : null
    };

    this.setState({ formData: newFormData });
  }

  private handleMarketAreaChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newFormData = {
      ...this.state.formData,
      marketArea: e.target.value
    };

    this.setState({ formData: newFormData }, this.validateMarketArea);
  }

  private handleRefitAssetClassChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newFormData = {
      ...this.state.formData,
      refitAssetClass: e.target.value
    };

    this.setState({ formData: newFormData }, this.validateRefitAssetClass);
  }

  private handleRefitBasePdctChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newFormData = {
      ...this.state.formData,
      refitBasePdct: e.target.value
    };

    this.setState({ formData: newFormData });
  }

  private handleRefitSubPdctChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newFormData = {
      ...this.state.formData,
      refitSubPdct: e.target.value
    };

    this.setState({ formData: newFormData });
  }

  private handleRefitAddtlSubPdctChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newFormData = {
      ...this.state.formData,
      refitAddtlSubPdct: e.target.value
    };

    this.setState({ formData: newFormData });
  }

  private handleRepoSurchargePercentChange = (value: number) => {
    const newFormData = {
      ...this.state.formData,
      repoSurchargePercent: value
    };

    this.setState({ formData: newFormData }, this.validateRepoSurchargePercent);
  }

  private handleSpreadChange = (value: number) => {
    const newFormData = {
      ...this.state.formData,
      spread: value
    };

    this.setState({ formData: newFormData }, this.validateSpread);
  }

  private handleTradingCurrencyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFormData = {
      ...this.state.formData,
      tradingCurrency: e.target.value
    };

    this.setState({ formData: newFormData }, this.validateTradingCurrency);
  }

  private handleCommodityBaseChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFormData = {
      ...this.state.formData,
      commodityBase: e.target.value
    };

    this.setState({ formData: newFormData });
  }

  private handleCommodityDetailsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFormData = {
      ...this.state.formData,
      commodityDetails: e.target.value
    };

    this.setState({ formData: newFormData });
  }

  private handleBaseCurrencyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFormData = {
      ...this.state.formData,
      baseCurrency: e.target.value
    };

    this.setState({ formData: newFormData });
  }

  private handleIndexNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFormData = {
      ...this.state.formData,
      indexName: e.target.value
    };

    this.setState({ formData: newFormData });
  }

  private handleEmirTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFormData = {
      ...this.state.formData,
      emirType: e.target.value
    };

    this.setState({ formData: newFormData }, this.validateEmirType);
  }

  private handleSubmit = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    if (this.validate()) {
      this.props.onSubmit(this.state.formData);
    }
  }

  private validateName = () => {
    const t = this.i18n.UnderlyingsView.ClientErrors;
    const value = this.state.formData.name;

    if (!value) {
      this.setState(state => ({
        fieldErrors: {...state.fieldErrors, name: t.NameFieldRequired}
      }));
      return false;
    } else {
      this.setState(state => ({
        fieldErrors: omit(state.fieldErrors, 'name')
      }));
      return true;
    }
  }

  private validateMdsCode = () => {
    const t = this.i18n.UnderlyingsView.ClientErrors;
    const value = this.state.formData.mdsCode;

    if (!value) {
      this.setState(state => ({
        fieldErrors: {...state.fieldErrors, mdsCode: t.MdsCodeFieldRequired}
      }));
      return false;
    } else {
      this.setState(state => ({
        fieldErrors: omit(state.fieldErrors, 'mdsCode')
      }));
      return true;
    }
  }

  private validateIsin = () => {
    const t = this.i18n.UnderlyingsView.ClientErrors;
    const value = this.state.formData.isin;

    if (!value) {
      this.setState(state => ({
        fieldErrors: {...state.fieldErrors, isin: t.IsinFieldRequired}
      }));
      return false;
    } else if (value.length !== 12) {
      this.setState(state => ({
        fieldErrors: {...state.fieldErrors, isin: t.IsinFieldIncorrect}
      }));
      return false;
    } else {
      this.setState(state => ({
        fieldErrors: omit(state.fieldErrors, 'isin')
      }));
      return true;
    }
  }

  private validateCfiCode = () => {
    const t = this.i18n.UnderlyingsView.ClientErrors;
    const value = this.state.formData.cfiCode;

    if (!value) {
      this.setState(state => ({
        fieldErrors: {...state.fieldErrors, cfiCode: t.CfiCodeFieldRequired}
      }));
      return false;
    } else {
      this.setState(state => ({
        fieldErrors: omit(state.fieldErrors, 'cfiCode')
      }));
      return true;
    }
  }

  private validateStartDate = () => {
    const t = this.i18n.UnderlyingsView.ClientErrors;
    const value = this.state.formData.startDate;

    if (!value) {
      this.setState(state => ({
        fieldErrors: {...state.fieldErrors, startDate: t.StartDateFieldRequired}
      }));
      return false;
    } else {
      this.setState(state => ({
        fieldErrors: omit(state.fieldErrors, 'startDate')
      }));
      return true;
    }
  }

  private validateMarketArea = () => {
    const t = this.i18n.UnderlyingsView.ClientErrors;
    const value = this.state.formData.marketArea;

    if (!value) {
      this.setState(state => ({
        fieldErrors: {...state.fieldErrors, marketArea: t.MarketAreaFieldRequired}
      }));
      return false;
    } else {
      this.setState(state => ({
        fieldErrors: omit(state.fieldErrors, 'marketArea')
      }));
      return true;
    }
  }

  private validateRefitAssetClass = () => {
    const t = this.i18n.UnderlyingsView.ClientErrors;
    const value = this.state.formData.refitAssetClass;

    if (!value) {
      this.setState(state => ({
        fieldErrors: {...state.fieldErrors, refitAssetClass: t.RefitAssetClassFieldRequired}
      }));
      return false;
    } else {
      this.setState(state => ({
        fieldErrors: omit(state.fieldErrors, 'refitAssetClass')
      }));
      return true;
    }
  }

  private validateTradingCurrency = () => {
    const t = this.i18n.UnderlyingsView.ClientErrors;
    const value = this.state.formData.tradingCurrency;

    if (!value) {
      this.setState(state => ({
        fieldErrors: {...state.fieldErrors, tradingCurrency: t.TradingCurrencyFieldRequired}
      }));
      return false;
    } else {
      this.setState(state => ({
        fieldErrors: omit(state.fieldErrors, 'tradingCurrency')
      }));
      return true;
    }
  }

  private validateExpiryDate = () => {
    const t = this.i18n.UnderlyingsView.ClientErrors;
    const value = this.state.formData.expiryDate;

    if (value && isWeekend(new Date(value))) {
      this.setState(state => ({
        fieldErrors: {...state.fieldErrors, expiryDate: t.ExpiryDateIncorrect}
      }));
      return false;
    } else {
      this.setState(state => ({
        fieldErrors: omit(state.fieldErrors, 'expiryDate')
      }));
      return true;
    }
  }

  private validateLastTradingDate = () => {
    const t = this.i18n.UnderlyingsView.ClientErrors;
    const value = this.state.formData.lastTradingDate;

    if (value && isWeekend(new Date(value))) {
      this.setState(state => ({
        fieldErrors: {...state.fieldErrors, lastTradingDate: t.LastTradingDateIncorrect}
      }));
      return false;
    } else {
      this.setState(state => ({
        fieldErrors: omit(state.fieldErrors, 'lastTradingDate')
      }));
      return true;
    }
  }

  private validateMaturityDate = () => {
    const t = this.i18n.UnderlyingsView.ClientErrors;
    const value = this.state.formData.maturityDate;

    if (value && isWeekend(new Date(value))) {
      this.setState(state => ({
        fieldErrors: {...state.fieldErrors, maturityDate: t.MaturityDateIncorrect}
      }));
      return false;
    } else {
      this.setState(state => ({
        fieldErrors: omit(state.fieldErrors, 'maturityDate')
      }));
      return true;
    }
  }

  private validateSpread = () => {
    const t = this.i18n.UnderlyingsView.ClientErrors;
    const value = this.state.formData.spread;

    if (value === null) {
      this.setState(state => ({
        fieldErrors: {...state.fieldErrors, spread: t.SpreadFieldRequired}
      }));
      return false;
    } else {
      this.setState(state => ({
        fieldErrors: omit(state.fieldErrors, 'spread')
      }));
      return true;
    }
  }

  private validateAlmParam = () => {
    const t = this.i18n.UnderlyingsView.ClientErrors;
    const value = this.state.formData.almParam;

    if (value === null) {
      this.setState(state => ({
        fieldErrors: {...state.fieldErrors, almParam: t.AlmParamFieldRequired}
      }));
      return false;
    } else {
      this.setState(state => ({
        fieldErrors: omit(state.fieldErrors, 'almParam')
      }));
      return true;
    }
  }

  private validateEligible871M = () => {
    const t = this.i18n.UnderlyingsView.ClientErrors;
    const value = this.state.formData.eligible871M;

    if (value === null) {
      this.setState(state => ({
        fieldErrors: {...state.fieldErrors, eligible871M: t.Eligible871MFieldRequired}
      }));
      return false;
    } else {
      this.setState(state => ({
        fieldErrors: omit(state.fieldErrors, 'eligible871M')
      }));
      return true;
    }
  }

  private validateRepoSurchargePercent = () => {
    const t = this.i18n.UnderlyingsView.ClientErrors;
    const value = this.state.formData.repoSurchargePercent;

    if (value === null) {
      this.setState(state => ({
        fieldErrors: {...state.fieldErrors, repoSurchargePercent: t.RepoSurchargePercentFieldRequired}
      }));
      return false;
    } else {
      this.setState(state => ({
        fieldErrors: omit(state.fieldErrors, 'repoSurchargePercent')
      }));
      return true;
    }
  }

  private validateEmirType = () => {
    const t = this.i18n.UnderlyingsView.ClientErrors;
    const value = this.state.formData.emirType;

    if (!value) {
      this.setState(state => ({
        fieldErrors: {...state.fieldErrors, emirType: t.EmirTypeFieldRequired}
      }));
      return false;
    } else {
      this.setState(state => ({
        fieldErrors: omit(state.fieldErrors, 'emirType')
      }));
      return true;
    }
  }

  private validate = () => {
    const validateList = [];
    if (!this.props.itemsToEdit) {
      validateList.push(
        this.validateName(),
        this.validateMdsCode(),
        this.validateIsin(),
        this.validateCfiCode(),
        this.validateStartDate(),
        this.validateMarketArea(),
        this.validateRefitAssetClass(),
        this.validateTradingCurrency(),
        this.validateSpread(),
        this.validateAlmParam(),
        this.validateEligible871M(),
        this.validateRepoSurchargePercent(),
        this.validateEmirType(),
        this.validateExpiryDate(),
        this.validateLastTradingDate(),
        this.validateMaturityDate(),
      );
    }
    return every(validateList);
  }

  private getServerErrorMessages(field: keyof Backoffice.Underlyings.UnderlyingContract) {
    return this.props.serverErrors
      ?.filter(e => e.errorCode as BackofficeErrorCodes === 'FieldValidationFailed' && e.fieldNames.includes(field))
      .map(e => e.errorMessage) ?? [];
  }

  private getClientErrorMessage(field: keyof Backoffice.Underlyings.UnderlyingContract) {
    return this.state.fieldErrors[field];
  }

  private formatErrorMessages(messages: string[]) {
    return messages.filter(m => !!m).join('; ');
  }

  public render() {
    const t = this.i18n.UnderlyingsView;

    const minDate = moment(getMinCalendarDate());
    const maxDate = moment(getMaxCalendarDate());

    return (
      <LykkeForm>
        <div className={Classes.DIALOG_BODY}>
          <Space direction='vertical' style={{width: '100%'}}>
            <Row className='flex-1' gutter={32}>
              <Col span={24}>
                <StyledBlockDiv>
                  <div>
                    {
                      this.props.itemsToEdit ?
                      <Row className='flex-1' gutter={32}>
                        <Col span={24}>{this.props.itemsToEdit.map(x => <div>{x.mdsCode}</div>)}</Col>
                      </Row> :
                      <Row className='flex-1' gutter={32}>
                        <Col span={6}>
                          <LabelWithTextInput
                            autoFocus={true}
                            label={t.NameLabel}
                            value={this.state.formData.name}
                            maxLength={100}
                            onChange={this.handleNameChange}
                            onBlur={this.validateName}
                            error={this.formatErrorMessages([this.getClientErrorMessage('name'), ...this.getServerErrorMessages('name')])}
                          />
                        </Col>
                        <Col span={6}>
                          <LabelWithTextInput
                            label={t.MdsCodeLabel}
                            value={this.state.formData.mdsCode}
                            maxLength={100}
                            disabled={!!this.props.itemToEdit}
                            onChange={this.handleMdsCodeChange}
                            onBlur={this.validateMdsCode}
                            error={this.formatErrorMessages([this.getClientErrorMessage('mdsCode'), ...this.getServerErrorMessages('mdsCode')])}
                          />
                        </Col>
                        {
                          this.props.itemToEdit &&
                          <>
                            <Col span={6} style={{ paddingTop: '20px' }}>
                              <Button
                                onClick={() => this.props.onRenameUnderlyingMdsCodeClick(this.props.itemToEdit.mdsCode)}
                                disabled={this.props.loading}
                                intent={Intent.PRIMARY}
                              >
                                {t.RenameMdsButtonLabel}
                              </Button>
                            </Col>
                            <Col span={6} style={{ whiteSpace: 'nowrap', paddingTop: '20px' }}>
                              <Button
                                onClick={() => this.props.onEditFrozenStatusByMdsCode(this.props.itemToEdit.mdsCode, true)}
                                disabled={this.props.loading}
                                intent={Intent.PRIMARY}
                              >
                                {t.FreezeProductsButtonLabel}
                              </Button>
                              <Button
                                onClick={() => this.props.onEditFrozenStatusByMdsCode(this.props.itemToEdit.mdsCode, false)}
                                disabled={this.props.loading}
                                intent={Intent.PRIMARY}
                              >
                                {t.UnfreezeProductsButtonLabel}
                              </Button>
                            </Col>
                          </>
                        }
                      </Row>
                    }
                  </div>
                </StyledBlockDiv>
              </Col>
            </Row>
            <Row className='flex-1' gutter={32}>
              <Col span={12}>
                <Space direction='vertical' style={{width: '100%'}}>
                  <StyledBlockDiv>
                    <h5>{t.IdentifiersSectionTitle}</h5>
                    <div>
                      <Row className='flex-1' gutter={32}>
                        {
                          !this.props.itemsToEdit &&
                          <Col span={12}>
                            <LabelWithTextInput
                              label={t.IsinLabel}
                              value={this.state.formData.isin}
                              maxLength={12}
                              onChange={this.handleIsinChange}
                              onBlur={this.validateIsin}
                              error={this.formatErrorMessages([this.getClientErrorMessage('isin'), ...this.getServerErrorMessages('isin')])}
                            />
                          </Col>
                        }
                        <Col span={12}>
                          <LabelWithTextInput
                            label={t.CfiCodeLabel}
                            value={this.state.formData.cfiCode}
                            maxLength={100}
                            onChange={this.handleCfiCodeChange}
                            onBlur={this.validateCfiCode}
                            error={this.formatErrorMessages([this.getClientErrorMessage('cfiCode'), ...this.getServerErrorMessages('cfiCode')])}
                          />
                        </Col>
                      </Row>
                    </div>
                  </StyledBlockDiv>
                  <StyledBlockDiv>
                    <h5>{t.DetailsSectionTitle}</h5>
                    <div>
                      <Row className='flex-1' gutter={32}>
                        <Col span={12}>
                          <TripleStateSelect
                            label={t.Eligible871MLabel}
                            value={this.state.formData.eligible871M}
                            onChange={this.handleEligible871MChange}
                            error={this.formatErrorMessages([this.getClientErrorMessage('eligible871M'), ...this.getServerErrorMessages('eligible871M')])}
                          />
                        </Col>
                        <Col span={12}>
                          <LabelWithSelect
                            label={t.MarketAreaLabel}
                            value={this.state.formData.marketArea}
                            options={this.marketAreaOptions}
                            onChange={this.handleMarketAreaChange}
                            onBlur={this.validateMarketArea}
                            error={this.formatErrorMessages([this.getClientErrorMessage('marketArea'), ...this.getServerErrorMessages('marketArea')])}
                          />
                        </Col>
                        <Col span={12}>
                          <LabelWithTextInput
                            label={t.IndexNameLabel}
                            value={this.state.formData.indexName}
                            maxLength={100}
                            onChange={this.handleIndexNameChange}
                            error={this.formatErrorMessages([...this.getServerErrorMessages('indexName')])}
                          />
                        </Col>
                        <Col span={12}>
                          <LabelWithTextInput
                            label={t.EmirTypeLabel}
                            value={this.state.formData.emirType}
                            maxLength={100}
                            onChange={this.handleEmirTypeChange}
                            onBlur={this.validateEmirType}
                            error={this.formatErrorMessages([this.getClientErrorMessage('emirType'), ...this.getServerErrorMessages('emirType')])}
                          />
                        </Col>
                        <Col span={12}>
                          <LabelWithTextInput
                            label={t.CommodityBaseLabel}
                            value={this.state.formData.commodityBase}
                            maxLength={100}
                            onChange={this.handleCommodityBaseChange}
                            error={this.formatErrorMessages([...this.getServerErrorMessages('commodityBase')])}
                          />
                        </Col>
                        <Col span={12}>
                          <LabelWithTextInput
                            label={t.CommodityDetailsLabel}
                            value={this.state.formData.commodityDetails}
                            maxLength={100}
                            onChange={this.handleCommodityDetailsChange}
                            error={this.formatErrorMessages([...this.getServerErrorMessages('commodityDetails')])}
                          />
                        </Col>
                        <Col span={12}>
                          <LabelWithSelect
                            label={t.RefitAssetClassLabel}
                            value={this.state.formData.refitAssetClass}
                            options={this.refitAssetClassOptions}
                            onChange={this.handleRefitAssetClassChange}
                            onBlur={this.validateRefitAssetClass}
                            error={this.formatErrorMessages([this.getClientErrorMessage('refitAssetClass'), ...this.getServerErrorMessages('refitAssetClass')])}
                          />
                        </Col>
                        <Col span={12}>
                          <LabelWithSelect
                            label={t.RefitBasePdctLabel}
                            value={this.state.formData.refitBasePdct}
                            options={this.refitBasePdctOptions}
                            onChange={this.handleRefitBasePdctChange}
                            error={this.formatErrorMessages([this.getClientErrorMessage('refitBasePdct'), ...this.getServerErrorMessages('refitBasePdct')])}
                          />
                        </Col>
                        <Col span={12}>
                          <LabelWithSelect
                            label={t.RefitSubPdctLabel}
                            value={this.state.formData.refitSubPdct}
                            options={this.refitSubPdctOptions}
                            onChange={this.handleRefitSubPdctChange}
                            error={this.formatErrorMessages([this.getClientErrorMessage('refitSubPdct'), ...this.getServerErrorMessages('refitSubPdct')])}
                          />
                        </Col>
                        <Col span={12}>
                          <LabelWithSelect
                            label={t.RefitAddtlSubPdctLabel}
                            value={this.state.formData.refitAddtlSubPdct}
                            options={this.refitAddtlSubPdctOptions}
                            onChange={this.handleRefitAddtlSubPdctChange}
                            error={this.formatErrorMessages([this.getClientErrorMessage('refitAddtlSubPdct'), ...this.getServerErrorMessages('refitAddtlSubPdct')])}
                          />
                        </Col>
                      </Row>
                    </div>
                  </StyledBlockDiv>
                </Space>
              </Col>
              <Col span={12}>
                <Space direction='vertical' style={{width: '100%'}}>
                  <StyledBlockDiv>
                    <h5>{t.DatesSectionTitle}</h5>
                    <div>
                      <Row className='flex-1' gutter={32}>
                        <Col span={12}>
                          <LabelWithDateInput
                            label={t.StartDateLabel}
                            value={toMoment(this.state.formData.startDate)}
                            onChange={this.handleStartDateChange}
                            onBlur={this.validateStartDate}
                            error={this.formatErrorMessages([this.getClientErrorMessage('startDate'), ...this.getServerErrorMessages('startDate')])}
                            minDate={minDate}
                            maxDate={maxDate}
                          />
                        </Col>
                        <Col span={12}>
                          <LabelWithDateInput
                            label={t.ExpiryDateLabel}
                            value={toMoment(this.state.formData.expiryDate)}
                            onChange={this.handleExpiryDateChange}
                            error={this.formatErrorMessages([this.getClientErrorMessage('expiryDate'), ...this.getServerErrorMessages('expiryDate')])}
                            minDate={minDate}
                            maxDate={maxDate}
                            onBlur={this.validateExpiryDate}
                          />
                        </Col>
                        <Col span={12}>
                          <LabelWithDateInput
                            label={t.LastTradingDateLabel}
                            value={toMoment(this.state.formData.lastTradingDate)}
                            onChange={this.handleLastTradingDateChange}
                            error={this.formatErrorMessages([this.getClientErrorMessage('lastTradingDate'), ...this.getServerErrorMessages('lastTradingDate')])}
                            minDate={minDate}
                            maxDate={maxDate}
                            onBlur={this.validateLastTradingDate}
                          />
                        </Col>
                        <Col span={12}>
                          <LabelWithDateInput
                            label={t.MaturityDateLabel}
                            value={toMoment(this.state.formData.maturityDate)}
                            onChange={this.handleMaturityDateChange}
                            error={this.formatErrorMessages([this.getClientErrorMessage('maturityDate'), ...this.getServerErrorMessages('maturityDate')])}
                            minDate={minDate}
                            maxDate={maxDate}
                            onBlur={this.validateMaturityDate}
                          />
                        </Col>
                      </Row>
                    </div>
                  </StyledBlockDiv>
                  <StyledBlockDiv>
                    <h5>{t.CurrencyAndSpreadSectionTitle}</h5>
                    <div>
                      <Row className='flex-1' gutter={32}>
                        <Col span={12}>
                          <LabelWithTextInput
                            label={t.BaseCurrencyLabel}
                            value={this.state.formData.baseCurrency}
                            maxLength={100}
                            onChange={this.handleBaseCurrencyChange}
                            error={this.formatErrorMessages([...this.getServerErrorMessages('baseCurrency')])}
                          />
                        </Col>
                        <Col span={12}>
                          <LabelWithTextInput
                            label={t.TradingCurrencyLabel}
                            value={this.state.formData.tradingCurrency}
                            maxLength={100}
                            onChange={this.handleTradingCurrencyChange}
                            onBlur={this.validateTradingCurrency}
                            error={this.formatErrorMessages([this.getClientErrorMessage('tradingCurrency'), ...this.getServerErrorMessages('tradingCurrency')])}
                          />
                        </Col>
                        <Col span={12}>
                          <LabelWithNumberInput
                            label={t.SpreadLabel}
                            value={this.state.formData.spread}
                            precision={5}
                            minimumValue={0}
                            onChange={this.handleSpreadChange}
                            onBlur={this.validateSpread}
                            error={this.formatErrorMessages([this.getClientErrorMessage('spread'), ...this.getServerErrorMessages('spread')])}
                          />
                        </Col>
                      </Row>
                    </div>
                  </StyledBlockDiv>
                  <StyledBlockDiv>
                    <h5>{t.CalculationParametersSectionTitle}</h5>
                    <div>
                      <Row className='flex-1' gutter={32}>
                        <Col span={12}>
                          <LabelWithNumberInput
                            label={t.AlmParamLabel}
                            value={this.state.formData.almParam}
                            precision={2}
                            minimumValue={0}
                            onChange={this.handleAlmParamChange}
                            onBlur={this.validateAlmParam}
                            error={this.formatErrorMessages([this.getClientErrorMessage('almParam'), ...this.getServerErrorMessages('almParam')])}
                          />
                        </Col>
                        <Col span={12}>
                          <LabelWithNumberInput
                            label={t.RepoSurchargePercentLabel}
                            value={this.state.formData.repoSurchargePercent}
                            precision={2}
                            minimumValue={0}
                            onChange={this.handleRepoSurchargePercentChange}
                            onBlur={this.validateRepoSurchargePercent}
                            error={this.formatErrorMessages([this.getClientErrorMessage('repoSurchargePercent'), ...this.getServerErrorMessages('repoSurchargePercent')])}
                          />
                        </Col>
                      </Row>
                    </div>
                  </StyledBlockDiv>
                </Space>
              </Col>
            </Row>
          </Space>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              onClick={this.props.onCancel}
              disabled={this.props.loading}
              intent={Intent.NONE}
            >
              {t.CancelButtonLabel}
            </Button>
            <Button
              type='submit'
              onClick={this.handleSubmit}
              disabled={this.props.loading}
              loading={this.props.loading}
              intent={Intent.SUCCESS}
            >
              {this.props.itemToEdit
                ? t.UpdateButtonLabel
                : this.props.itemToClone
                  ? t.CloneOkButtonLabel
                  : t.CreateButtonLabel}
            </Button>
          </div>
        </div>
      </LykkeForm>
    );
  }
}